import React, { useState, useEffect, useCallback } from 'react';
import { View, Text, TouchableOpacity, ScrollView } from 'react-native';
import { FloatingAction } from 'react-native-floating-action';
import { useIsFocused } from '@react-navigation/native';

import api from '../../services/api';

const Gerentes = ({ navigation }) => {
  const isFocused = useIsFocused();
  const [gerentes, setGerentes] = useState([]);

  const handleObterGerentes = useCallback(async () => {
    const { data } = await api.get('gerentes');
    data.sort((a, b) => a.nome.localeCompare(b.nome));
    setGerentes(data);
    localStorage.setItem('gerentes', JSON.stringify(data));
  }, []);

  useEffect(() => {
    const gerentes = JSON.parse(localStorage.getItem('gerentes'));
    if (gerentes) {
      setGerentes(gerentes);
    }
    handleObterGerentes();
  }, [isFocused, navigation, handleObterGerentes]);

  const abrirCadastro = () => {
    navigation.navigate('GerenteCadastro');
  }

  const excluirGrente = (id) => {
    const x = confirm('Deseja realmente excluir este gerente?');
    if (x) {
      api.delete(`gerentes/${id}`).then(() => {
        alert('Gerente excluido com sucesso!');
        handleObterGerentes();
      }).catch(() => {
        alert('Falha ao excluir o gerente.');
      })
    }
  }

  const resetarSenha = (id) => {
    const x = confirm('Deseja realmente resetar a senha deste gerente para 123456 novamente?');
    if (x) {
      api.patch(`resetar_senha/${id}`).then(() => {
        alert('Senha resetada com sucesso!');
        handleObterGerentes()
      }).catch(() => {
        alert('Falha ao redefinir a senha do gerente.');
      })
    }
  }

  return (
    <View style={{ flex: 1 }}>
      <View style={{ margin: 20, flex: 1 }}>
        <ScrollView style={{ paddingBottom: 50 }}>
          {gerentes.map(gerente => (
            <View key={gerente.id} style={{ flexDirection: 'row', backgroundColor: '#fff', padding: 10, marginTop: 10, borderRadius: 5 }}>
              <View style={{ flex: 2 }}>
                <Text style={{ flex: 1, fontWeight: 'bold' }}>{gerente.nome}</Text>
                <Text style={{ flex: 1 }}>{gerente.email}</Text>
              </View>
              <View style={{ flex: 1 }}>
                <TouchableOpacity onPress={() => resetarSenha(gerente.id)}>
                  <Text style={{ background: '#1abc9c', fontSize:12,padding:2.5, color: '#fff', textAlign: 'center', borderRadius: 5 }}>Resetar senha</Text>
                </TouchableOpacity>
                <TouchableOpacity onPress={() => excluirGrente(gerente.id)}>
                  <Text style={{ background: '#e74c3c',  fontSize:12,padding:2.5,color: '#fff', marginTop: 12.5, textAlign: 'center', borderRadius: 5 }}>Excluir</Text>
                </TouchableOpacity>
              </View>
            </View>
          ))}

          {!gerentes.length && (
            <View style={{ backgroundColor: '#fff', padding: 10, marginTop: 10, borderRadius: 5, flexDirection: 'row' }}>
              <Text style={{ flex: 1, textAlign: 'center' }}>Nenhum gerente cadastrado</Text>
            </View>
          )}

          <View style={{ marginBottom: 50 }}></View>
        </ScrollView>
      </View>
        
      <FloatingAction
        color='#1abc9c'
        showBackground={false}
        animated={false}
        onPressMain={abrirCadastro}
      />
    </View>
  );
}

export default Gerentes;