import React, { useState, useEffect, useCallback } from 'react';
import { View, Text, TouchableOpacity, ScrollView } from 'react-native';
import { FloatingAction } from 'react-native-floating-action';
import api from '../../services/api';

const VincularVendedor = ({ route, navigation }) => {
  const [vendedores, setVendedores] = useState([]);

  const handleObterVendedores = useCallback(async () => {
    const { data } = await api.get('vendedores');
    const v = data.sort((a, b) => a.nome.localeCompare(b.nome));
    setVendedores(v);
  }, [])

  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      handleObterVendedores()
    });
    return unsubscribe;
  }, [navigation])

  const vincularVendedor = (vendedor) => {
    const id = route.params.id

    api.post(`vincularvendedor`, { sorteio: id, vendedor }).then(() => {
      alert('Vendedor vinculado com sucesso');
      navigation.goBack();
    }).catch((e) => {
      if (e.response.data.msg) {
        alert(e.response.data.msg);
      } else {
        alert('Falha ao vincular vendedor ou ele ja esta vinculado');
      }
    })
  }

  const abrirCadastro = () => {
    navigation.navigate('VendedorCadastro')
  }

  return (
    <View style={{ flex: 1 }}>
      <View style={{ margin: 20, flex: 1 }}>
        <ScrollView>
          {vendedores.length > 0 ?
            vendedores.map(e => (
              <TouchableOpacity onPress={() => vincularVendedor(e.id)}>
                <View style={{ backgroundColor: '#fff', padding: 10, marginTop: 10, borderRadius: 5 }}>
                  <Text style={{ flex: 1, fontWeight: 'bold' }}>{e.nome}</Text>
                  <Text style={{ flex: 1 }}>{e.email}</Text>
                </View>
              </TouchableOpacity>
          )) : (
            <View style={{ backgroundColor: '#fff', padding: 10, marginTop: 10, borderRadius: 5, flexDirection: 'row' }}>
              <Text style={{ flex: 1, textAlign: 'center' }}>Nenhum vendedor cadastrado</Text>
            </View>
          )}
        </ScrollView>
      </View>

      <FloatingAction
        color='#1abc9c'
        showBackground={false}
        animated={false}
        onPressMain={() => abrirCadastro()}
      />
    </View>
  );
}

export default VincularVendedor;