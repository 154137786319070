import React, { useState, useEffect, useCallback } from 'react';
import { View, Text, TouchableOpacity, ScrollView } from 'react-native';
import { FloatingAction } from 'react-native-floating-action';
import { useIsFocused } from '@react-navigation/native';

import api from '../../services/api';

const Vendedores = ({ navigation }) => {
  const isFocused = useIsFocused();
  const [vendedores, setVendedores] = useState([]);

  const handleObterVendedores = useCallback(async () => {
    const { data } = await api.get('vendedores');
    setVendedores(data);
    localStorage.setItem('vendedores', JSON.stringify(data));
  }, []);

  useEffect(() => {
    const vendedores = JSON.parse(localStorage.getItem('vendedores'));
    if (vendedores) {
      setVendedores(vendedores);
    }
    handleObterVendedores();
  }, [isFocused, navigation, handleObterVendedores]);

  const abrirCadastro = () => {
    navigation.navigate('VendedorCadastro');
  }

  const excluirVendedor = (id) => {
    const x = confirm('Deseja realmente excluir este vendedor?');
    if (x) {
      api.delete(`vendedores/${id}`).then(() => {
        alert('Vendedor excluido com sucesso!');
        handleObterVendedores();
      }).catch(() => {
        alert('Falha ao excluir o vendedor.');
      })
    }
  }

  const resetarSenha = (id) => {
    const x = confirm('Deseja realmente resetar a senha deste vendedor para 123456 novamente?');
    if (x) {
      api.patch(`resetar_senha/${id}`).then(() => {
        alert('Senha resetada com sucesso!');
        handleObterVendedores()
      }).catch(() => {
        alert('Falha ao redefinir a senha do vendedor.');
      })
    }
  }

  return (
    <View style={{ flex: 1 }}>
      <View style={{ margin: 20, flex: 1 }}>
        <ScrollView style={{ paddingBottom: 50 }}>
          {vendedores.map(vendedor => (
            <View key={vendedor.id} style={{ flexDirection: 'row', backgroundColor: '#fff', padding: 10, marginTop: 10, borderRadius: 5 }}>
              <View style={{ flex: 2 }}>
                <Text style={{ flex: 1, fontWeight: 'bold' }}>{vendedor.nome}</Text>
                <Text style={{ flex: 1 }}>{vendedor.email}</Text>
              </View>
              <View style={{ flex: 1 }}>
                <TouchableOpacity onPress={() => resetarSenha(vendedor.id)}>
                  <Text style={{ background: '#1abc9c', fontSize:12,padding:2.5, color: '#fff', textAlign: 'center', borderRadius: 5 }}>Resetar senha</Text>
                </TouchableOpacity>
                <TouchableOpacity onPress={() => excluirVendedor(vendedor.id)}>
                  <Text style={{ background: '#e74c3c',  fontSize:12,padding:2.5,color: '#fff', marginTop: 12.5, textAlign: 'center', borderRadius: 5 }}>Excluir</Text>
                </TouchableOpacity>
              </View>
            </View>
          ))}

          {!vendedores.length && (
            <View style={{ backgroundColor: '#fff', padding: 10, marginTop: 10, borderRadius: 5, flexDirection: 'row' }}>
              <Text style={{ flex: 1, textAlign: 'center' }}>Nenhum vendedor cadastrado</Text>
            </View>
          )}

          <View style={{ marginBottom: 50 }}></View>
        </ScrollView>
      </View>
        
      <FloatingAction
        color='#1abc9c'
        showBackground={false}
        animated={false}
        onPressMain={abrirCadastro}
      />
    </View>
  );
}

export default Vendedores;