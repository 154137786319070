import React, { useCallback, useEffect, useState } from 'react';
import { View, Text, Image } from 'react-native';

import api from '../services/api';

const ClienteCartelaID = ({ route, navigation }) => {
  const [nome, setNome] = useState('');
  const [whatsapp, setWhatsapp] = useState('');
  const [cartelas, setCartelas] = useState([]);

  const handleLoad = useCallback(async () => {
    const { data } = await api.get(`sorteios/${route.params.sorteio_id}/clientes/${route.params.cliente_id}`);
    setNome(data.cliente.nome);
    setWhatsapp(data.cliente.whatsapp);
    setCartelas(data.cartelas);

    localStorage.removeItem('sorteio_id');
    localStorage.removeItem('cliente_id');
  }, []);

  useEffect(() => {
    handleLoad();
  }, [handleLoad]);

  return (
    <div id='paginaprint' style={{ flex: 1, width: '100%' }}>
      <View style={{ margin: 20, flex: 1 }}>
        <View style={{ alignItems: 'center' }}>
          <Image
            style={{ height: 200, width: 200, marginTop: -50, marginBottom: -50 }}
            source={require('../assets/logo.png')}
          />
        </View>

        <View style={{ backgroundColor: '#fff', padding: 10, marginTop: 10, borderRadius: 5, flexDirection: 'row' }}>
          <View style={{ flex: 1 }}>
            <View>
              <Text style={{ fontWeight: 'bold', textAlign: 'center' }}>Nome Cliente</Text>
              <Text style={{ textAlign: 'center' }}>{nome}</Text>
            </View>
          </View>
          <View style={{ flex: 1 }}>
            <View>
              <Text style={{ fontWeight: 'bold', textAlign: 'center' }}>Whatsapp</Text>
              <Text style={{ textAlign: 'center' }}>{whatsapp}</Text>
            </View>
          </View>
        </View>

        {cartelas.map((e, index) => (
          <View onPress={() => navigation.navigate('ClientesCartelas', { dados: e })} style={{ backgroundColor: '#fff', padding: 10, borderRadius: 5, flexDirection: 'row', marginTop: 10 }}>
            <View>
              <Image
                style={{ height: cartelas[0].numeros.length > 3 ? 100 : 150, width: cartelas[0].numeros.length > 3 ? 100 : 150 }}
                source={require('../assets/logo.png')}
              />
              <Text style={{ fontSize: 9, textAlign: 'center', marginTop: 5 }}>{cartelas[0].id}</Text>
            </View>

            <View style={{ flex: 1 }}>
              {cartelas[index].numeros.map((numero, index) => (
                <View key={index} style={{ alignItems: 'center' }}>
                  <Text style={{ textAlign: 'center', borderWidth: 1, fontSize: 18, borderColor: '#95a5a6', padding: 5, paddingLeft: 30, paddingRight: 30, borderRadius: 10, margin: 5 }}>
                    {numero}
                  </Text>
                </View>
              ))}
            </View>
          </View>
        ))}
      </View>
    </div >
  );
}

export default ClienteCartelaID;