import React, { useCallback, useEffect, useState } from 'react';
import { View, Text } from 'react-native';

import { useIsFocused } from '@react-navigation/native';

import api from '../services/api';
import moment from 'moment';
import formatCurrency from '../utils/formatCurrency';

const Relatorio = ({ route, navigation }) => {
  const isFocused = useIsFocused();
  
  const [isLoading, setIsLoading] = useState(true);
  const [dataSorteio, setDataSorteio] = useState('');
  const [qtdVendido, setQtdVendido] = useState(0);
  const [vendedores, setVendedores] = useState([]);

  const handleObterRelatorio = useCallback(async (id) => {
    const { data } = await api.get(`sorteios/${id}/relatorio`);
    setVendedores(data);
    setIsLoading(false);
  }, [])

  useEffect(() => {
    setIsLoading(true);

    const sorteio = route.params.sorteio;
    const data_sorteio = new Date(sorteio.data_realizar_sorteio);
    data_sorteio.setDate(data_sorteio.getDate() + 1);
    setDataSorteio(data_sorteio);
    setQtdVendido(sorteio.valor_cartela * sorteio.quantidade_vendido);

    if (route.params.vendedores) {
      setVendedores(route.params.vendedores);
      setIsLoading(false);
    } else {
      handleObterRelatorio(sorteio.id)
    }
  }, [isFocused, navigation]);

  return (
    <View style={{ flex: 1 }}>
      <View style={{ margin: 20, flex: 1 }}>
        <View style={{ backgroundColor: '#fff', padding: 10, marginTop: 10, borderRadius: 5 }}>
          <View style={{ flexDirection: 'row' }}>
            <Text style={{ flex: 1, fontWeight: 'bold' }}>{route.params.sorteio.nome}</Text>
            <Text style={{}}>{route.params.sorteio.cidade.nome}</Text>
          </View>

          <View style={{ flexDirection: 'row', marginTop: 10 }}>
            <View style={{ flex: 1, flexDirection: 'row' }}>
              <Text style={{ fontWeight: 'bold' }}>Cadastro</Text>
              <Text>: {moment(route.params.sorteio.created_at).format('DD/MM/YYYY')}</Text>
            </View>

            <View style={{ flex: 1, flexDirection: 'row' }}>
              <Text style={{ fontWeight: 'bold' }}>Sorteio</Text>
              <Text>: {moment(dataSorteio).format('DD/MM/YYYY')}</Text>
            </View>
          </View>

          <View style={{ flexDirection: 'row', marginTop: 10 }}>
            <View style={{ flex: 1, flexDirection: 'row' }}>
              <Text style={{ fontWeight: 'bold' }}>Modelo</Text>
              <Text>: {route.params.sorteio.quantidade_chance} Chances</Text>
            </View>

            <View style={{ flex: 1, flexDirection: 'row' }}>
              <Text style={{ fontWeight: 'bold' }}>Vendedores</Text>
              <Text>: {route.params.sorteio.quantidade_vendedores}</Text>
            </View>
          </View>

          <View style={{ flexDirection: 'row', marginTop: 10 }}>
            <View style={{ flex: 1, flexDirection: 'row' }}>
              <Text style={{ fontWeight: 'bold' }}>Valor cartela</Text>
              <Text>: R$ {formatCurrency(route.params.sorteio.valor_cartela)}</Text>
            </View>

            <View style={{ flex: 1, flexDirection: 'row' }}>
              <Text style={{ fontWeight: 'bold' }}>Total vendido</Text>
              <Text>: R$ {formatCurrency(qtdVendido)}</Text>
            </View>
          </View>
        </View>

        <View style={{ backgroundColor: '#fff', padding: 10, marginTop: 10, borderRadius: 5 }}>
          <View>
            {isLoading && (
              <View>
                <Text style={{ textAlign: 'center' }}>Carregando...</Text>
              </View>
            )}
            
            {vendedores.length > 0 ?
              vendedores.map(vendedor => (
                <View key={vendedor.id} style={{ borderBottomWidth: 1, borderBottomColor: '#ecf0f1', paddingBottom: 10, flexDirection: 'row' }}>
                  <View style={{ flex: 1, flexDirection: 'row' }}>
                    <Text style={{ flex: 1, fontSize: 12 }}>{vendedor.nome}</Text>
                  </View>

                  <View style={{ flex: 1, flexDirection: 'row' }}>
                    <Text style={{ flex: 1, fontSize: 12, fontWeight: 'bold', marginRight: 5 }}>Cartelas</Text>
                    <Text style={{ flex: 1, fontSize: 12 }}>{vendedor.quantidade_cartelas}</Text>
                  </View>

                  <View style={{ flex: 1, flexDirection: 'row' }}>
                    <Text style={{ flex: 1, fontSize: 12, fontWeight: 'bold', marginRight: 5 }}>Vendidos</Text>
                    <Text style={{ flex: 1, fontSize: 12 }}>{vendedor.quantidade_cartelas_vendidas}</Text>
                  </View>

                  <View style={{ flex: 1, flexDirection: 'row-reverse' }}>
                    <Text style={{ fontSize: 12 }}>R$ {formatCurrency(route.params.sorteio.valor_cartela * vendedor.quantidade_cartelas_vendidas)}</Text>
                  </View>
                </View>
              )
            ) : !isLoading && (
              <View>
                <Text style={{ textAlign: 'center' }}>Nenhum vendedor vinculado</Text>
              </View>
            )}
          </View>
        </View>
      </View>
    </View>
  );
}

export default Relatorio;