import React from 'react';
import FontAwesome5 from '@expo/vector-icons/Ionicons';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';

const Tab = createBottomTabNavigator();

import Perfil from '../pages/usuario'
import Sorteios from './routes-sorteios'
import Cadastros from '../pages/cadastros';

const RoutesTabs = () => (
  <Tab.Navigator>
    <Tab.Screen 
      name='Sorteios' 
      component={Sorteios} 
      options={{
        tabBarIcon: ({ color, size }) => (
          <FontAwesome5 name='home-outline' color={color} size={size} />
        )
      }} 
    />
    <Tab.Screen 
      name='Cadastros' 
      component={Cadastros} 
      options={{
        tabBarIcon: ({ color, size }) => (
          <FontAwesome5 name='people-outline' color={color} size={size} />
        )
      }} 
    />
    <Tab.Screen 
      name='Perfil' 
      component={Perfil} 
      options={{
        tabBarIcon: ({ color, size }) => (
          <FontAwesome5 name='person-outline' color={color} size={size} />
        )
      }} 
    />
  </Tab.Navigator>
);

export default RoutesTabs;