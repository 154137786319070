import React, { useState, useEffect } from 'react';
import { View, Text, TextInput, TouchableOpacity } from 'react-native';
import api from '../services/api';

const pages = ({ route, navigation }) => {
  let [numeroUm, setNumeroUm] = useState('')
  let [numeroDois, setNumeroDois] = useState('')
  let [numeroTres, setNumeroTres] = useState('')
  let [numeroQuatro, setNumeroQuatro] = useState('')
  let [numeroCinco, setNumeroCinco] = useState('')
  let [numeroSeis, setNumeroSeis] = useState('')
  let [possiveis, setPossiveis] = useState([])
  let [sorteioAtivo, setSorteioAtivo] = useState(true)
  let [ganhador, setGanhador] = useState([])

  useEffect(() => {
    cartela();
  }, [numeroUm, numeroDois, numeroTres, numeroQuatro, numeroCinco, numeroSeis])

  const cartela = async () => {
    const { data } = await api.get(`sorteios/${route.params.id}/cartelas`);
    possiveisGanhadores(data);
  }

  const possiveisGanhadores = (numeros) => {
    let x = numeroUm + numeroDois + numeroTres + numeroQuatro
    if (x.length > 0) {
      let possiveisNumeros = []

        // Percorre a lista de numeros
      for (let i = 0; i < numeros.length; i++) {

        let aindaPodeGanhar = false

        // Percorre todos os numeros da lista de numeros
        for (let ii = 0; ii < numeros[i].numeros.length; ii++) {
          let numero1 = '';
          let numero2 = '';

          for (let iii = 0; iii < x.length; iii++) {
            numero1 += x.charAt(iii)
          }

          for (let iii = 0; iii < x.length; iii++) {
            numero2 += numeros[i].numeros[ii].toString().charAt(iii)
          }

          if (numero1 == numero2) {
            aindaPodeGanhar = true
          }
        }

        if (aindaPodeGanhar) {
          possiveisNumeros.push(numeros[i])
        }
      }

      setPossiveis(possiveisNumeros)

      if (4 == x.length) {
        sorteioTerminou(possiveisNumeros)
      }
    } else {
      setPossiveis(numeros);
    }
  }

  const sorteioTerminou = possiveisNumeros => {
    if (possiveisNumeros.length > 0) {
      setGanhador(possiveisNumeros)
    }
    setSorteioAtivo(false);
  }

  const finalizarSorteio = async () => {
    const x = confirm('Deseja realmente finalizar o sorteio?');
    if (!x) {
      return;
    }

    const s = confirm('Deseja repetir o sorteio após a finalização?');

    try {
      await api.patch(`sorteios/${route.params.id}/finalizar`, {
        numero_sorteado: numeroUm + numeroDois + numeroTres + numeroQuatro
      });
      
      if (s) {
        await api.post(`sorteios/${route.params.id}/clonar`);
      }
  
      navigation.reset({
        index: 0,
        routes: [{ name: 'Tabs' }]
      });
    } catch (error) {
      alert('Falha ao finalizar sorteio');
    }
  }

  return (
    <View style={{ flex: 1 }}>
      <View style={{ margin: 20, flex: 1 }}>
        <View style={{ backgroundColor: '#fff', padding: 10, marginTop: 10, borderRadius: 5 }}>
          <View style={{ flexDirection: 'row' }}>
            <Text style={{ flex: 1, fontWeight: 'bold' }}>{route.params.nome}</Text>
            <Text>{route.params.cidade.nome}</Text>
          </View>

          {sorteioAtivo ? (
            <View style={{ marginTop: 10 }}>
              <Text style={{ fontWeight: 'bold', textAlign: 'center' }}>Possíveis ganhadores</Text>
              <Text style={{ textAlign: 'center' }}>{possiveis.length}</Text>
            </View>
          ) : (
            <View style={{ marginTop: 10 }}>
              <Text style={{ fontWeight: 'bold', textAlign: 'center' }}>Sorteio finalizado</Text>
              <Text style={{ textAlign: 'center' }}>{ganhador.length > 0 ? ganhador[0].cliente.nome + ' (' + ganhador[0].vendedor.nome + ')' : 'Nenhum ganhador'}</Text>
            </View>
          )}
        </View>

        <View style={{ backgroundColor: '#fff', padding: 10, marginTop: 10, borderRadius: 5 }}>
          <View style={{ flexDirection: 'row' }}>
            <View style={{ flex: 1 }}>
              <TextInput editable={sorteioAtivo} maxLength={1} value={numeroUm} onChangeText={setNumeroUm} style={{ marginTop: 10, backgroundColor: '#ecf0f1', color: '#34495e', borderRadius: 5, height: 50, margin: 5, fontSize: 20, textAlign: 'center' }}></TextInput>
            </View>
            <View style={{ flex: 1 }}>
              <TextInput editable={sorteioAtivo} maxLength={1} value={numeroDois} onChangeText={setNumeroDois} style={{ marginTop: 10, backgroundColor: '#ecf0f1', color: '#34495e', borderRadius: 5, height: 50, margin: 5, fontSize: 20, textAlign: 'center' }}></TextInput>
            </View>
            <View style={{ flex: 1 }}>
              <TextInput editable={sorteioAtivo} maxLength={1} value={numeroTres} onChangeText={setNumeroTres} style={{ marginTop: 10, backgroundColor: '#ecf0f1', color: '#34495e', borderRadius: 5, height: 50, margin: 5, fontSize: 20, textAlign: 'center' }}></TextInput>
            </View>

            <View style={{ flex: 1 }}>
              <TextInput editable={sorteioAtivo} maxLength={1} value={numeroQuatro} onChangeText={setNumeroQuatro} style={{ marginTop: 10, backgroundColor: '#ecf0f1', color: '#34495e', borderRadius: 5, height: 50, margin: 5, fontSize: 20, textAlign: 'center' }}></TextInput>
            </View>
          </View>
        </View>
          
        {!sorteioAtivo && (
          <TouchableOpacity style={{ marginTop: 25 }} onPress={finalizarSorteio}>
            <Text style={{ backgroundColor: '#1abc9c', color: '#fff', textAlign: 'center', padding: 7.5, borderRadius: 5 }}>Finalizar</Text>
          </TouchableOpacity>
        )}
      </View>
    </View>
  );
}

export default pages;