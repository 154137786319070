import React, { useState, useEffect, useCallback } from 'react';
import { View, Text, TouchableOpacity, ScrollView } from 'react-native';
import { FloatingAction } from 'react-native-floating-action';
import { useIsFocused } from '@react-navigation/native';

import api from '../../services/api';

const Cidades = ({ navigation }) => {
  const isFocused = useIsFocused();
  const [cidades, setCidades] = useState([]);

  const handleObterCidades = useCallback(async () => {
    const { data } = await api.get('cidades');
    data.sort((a, b) => a.nome.localeCompare(b.nome));
    setCidades(data);
    localStorage.setItem('cidades', JSON.stringify(data));
  }, []);

  useEffect(() => {
    const cidades = JSON.parse(localStorage.getItem('cidades'));
    if (cidades) {
      setCidades(cidades);
    }
    handleObterCidades();
  }, [isFocused, navigation, handleObterCidades]);

  const abrirCadastro = () => {
    navigation.navigate('CidadeCadastro');
  }

  const excluirCidade = (id) => {
    const x = confirm('Deseja realmente excluir esta cidade?');
    if (x) {
      api.delete(`cidades/${id}`).then(() => {
        handleObterCidades();
      }).catch((e) => {
        if (e.response.data.msg) {
          alert(e.response.data.msg)
        } else {
          alert('Falha ao excluir a cidade.');
        }
      })
    }
  }

  return (
    <View style={{ flex: 1 }}>
      <View style={{ margin: 20, flex: 1 }}>
        <ScrollView style={{ paddingBottom: 50 }}>
          {cidades.map(cidade => (
            <View style={{ flexDirection: 'row', backgroundColor: '#fff', padding: 10, marginTop: 10, borderRadius: 5 }}>
              <View style={{ flex: 2 }}>
                <Text style={{ flex: 1, fontWeight: 'bold' }}>{cidade.nome}</Text>
              </View>
              <View style={{ flex: 1 }}>
                <TouchableOpacity onPress={() => excluirCidade(cidade.id)}>
                  <Text style={{ background: '#e74c3c', fontSize:12, padding: 2.5, color: '#fff', textAlign: 'center', borderRadius: 5 }}>Excluir</Text>
                </TouchableOpacity>
              </View>
            </View>
          ))}

          {!cidades.length && (
            <View style={{ backgroundColor: '#fff', padding: 10, marginTop: 10, borderRadius: 5, flexDirection: 'row' }}>
              <Text style={{ flex: 1, textAlign: 'center' }}>Nenhuma cidade cadastrado</Text>
            </View>
          )}

          <View style={{ marginBottom: 50 }}></View>
        </ScrollView>
      </View>
        
      <FloatingAction
        color='#1abc9c'
        showBackground={false}
        animated={false}
        onPressMain={abrirCadastro}
      />
    </View>
  );
}

export default Cidades;