import { StyleSheet, View } from 'react-native';

import Routes from './src/router/routes'

import 'intl';
import 'intl/locale-data/jsonp/pt-BR';

export default function App() {
  return (
    <View style={styles.container}>
      <Routes />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1
  }
});