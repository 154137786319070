import React, { useCallback, useEffect, useState } from 'react';
import { View, Text, TouchableOpacity, Image } from 'react-native';
import api from '../services/api';
import moment from 'moment-timezone';

const SorteioDetalhe = ({ route, navigation }) => {
  const [nome, setNome] = useState('');
  const [cidade, setCidade] = useState('');
  const [dataAbertura, setDataAbertura] = useState('');
  const [dataSorteio, setDataSorteio] = useState('');
  const [quantidade, setQuantidade] = useState('');
  const [vendedores, setVendedores] = useState([]);
  const [loading, setLoading] = useState(false);
  const [statusVenda, setStatusVenda] = useState(true);

  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      setLoading(true);

      const dados = route.params.sorteio;
      setNome(dados.nome);
      setCidade(dados.cidade.nome);
      setDataAbertura(dados.created_at);
      const data_sorteio = new Date(dados.data_realizar_sorteio);
      data_sorteio.setDate(data_sorteio.getDate() + 1);
      setDataSorteio(data_sorteio);
      setQuantidade(dados.quantidade_chance);
      setStatusVenda(dados.situacao_venda);
      selecionaVendedores();
    });

    return unsubscribe;
  }, []);

  const selecionaVendedores = useCallback(async () => {
    const { data } = await api.get(`sorteios/vendedores/${route.params.sorteio.id}`);
    setVendedores(data);
    setLoading(false);
  }, [])

  const cancelarSorteio = () => {
    const x = confirm('Deseja realmente cancelar este sorteio?');
    if (x) {
      api.patch(`sorteios/${route.params.sorteio.id}/cancelar`).then(() => {
        navigation.goBack();
      });
    }
  }

  const fecharVendas = () => {
    const x = confirm('Deseja realmente fechar as vendas deste sorteio?');
    if (x) {
      api.patch(`sorteios/${route.params.sorteio.id}/fechar_venda`).then(() => {
        alert('Vendas deste sorteio foram fechadas');
        setStatusVenda(false);
      });
    }
  }

  const abrirVendas = async () => {
    const x = confirm('Deseja realmente abrir as vendas deste sorteio?');

    if (x) {
      api.patch(`sorteios/${route.params.sorteio.id}/abrir_venda`).then(() => {
        alert('Vendas deste sorteio foram reabertas');
        setStatusVenda(true);
      })
    }
  }

  const sortear = async () => {
    const x = confirm('Deseja realmente sortear agora?');
    if (x) {
      navigation.navigate('Sortear', route.params.sorteio)
    }
  }

  const removerVendedor = (idVendedor) => {
    const x = confirm('Deseja realmente remover esse vendedor?');
    if (x) {
      api.delete(`sorteios/${route.params.sorteio.id}/vendedores/${idVendedor}`).then(() => {
        selecionaVendedores();
      }).catch(() => {
        alert('Falha ao remover vendedor!')
      })
    }
  }

  return (
    <View style={{ flex: 1 }}>
      <View style={{ margin: 20, flex: 1, paddingBottom: 200 }}>
        <View style={{ backgroundColor: '#fff', padding: 10, marginTop: 10, borderRadius: 5 }}>
          <View style={{ flexDirection: 'row' }}>
            <Text style={{ flex: 1, fontWeight: 'bold' }}>{nome}</Text>
            <Text>{cidade}</Text>
          </View>

          <View style={{ flexDirection: 'row', marginTop: 10 }}>
            <View style={{ flex: 1, flexDirection: 'row' }}>
              <Text style={{ fontWeight: 'bold' }}>Cadastro:</Text>
              <Text style={{ paddingLeft: 5 }}>{moment(dataAbertura).format('DD/MM/YYYY')}</Text>
            </View>

            <View style={{ flex: 1, flexDirection: 'row' }}>
              <Text style={{ fontWeight: 'bold' }}>Sorteio:</Text>
              <Text style={{ paddingLeft: 5 }}>{moment(dataSorteio).format('DD/MM/YYYY')}</Text>
            </View>
          </View>

          <View style={{ flexDirection: 'row', marginTop: 10 }}>
            <View style={{ flex: 1, flexDirection: 'row' }}>
              <Text style={{ fontWeight: 'bold' }}>Modelo:</Text>
              <Text style={{ paddingLeft: 5 }}>{quantidade} Chances</Text>
            </View>

            <View style={{ flex: 1, flexDirection: 'row' }}>
              <Text style={{ fontWeight: 'bold' }}>Vendedores:</Text>
              <Text style={{ paddingLeft: 5 }}>{vendedores.length}</Text>
            </View>
          </View>
        </View>

        <View style={{ backgroundColor: '#fff', padding: 10, marginTop: 10, borderRadius: 5 }}>
          <View style={{ flexDirection: 'row' }}>
            <Text style={{ width: '100%', flex: 1, textAlign: 'right', position: 'absolute', fontSize: 12, color: statusVenda == true || statusVenda == 'ABERTO' ? '#1abc9c' : '#e74c3c' }}>{statusVenda == true || statusVenda == 'ABERTO' ? 'Vendas Ativa' : 'Vendas fechadas'}</Text>
              <TouchableOpacity onPress={() => navigation.navigate('vendedorVincular', { id: route.params.sorteio.id })}>
                <Text style={{ position: 'absolute', backgroundColor: '#1abc9c', color: '#fff', paddingLeft: 10, paddingRight: 10, borderRadius: 5 }}>+</Text>
              </TouchableOpacity>
            <Text style={{ flex: 1, fontWeight: 'bold', textAlign: 'center' }}>Vendedores</Text>
          </View>

          <View style={{ marginTop: 25 }}>
            {loading ? (
              <View style={{ backgroundColor: '#fff', padding: 10, marginTop: 10, borderRadius: 5, alignItems: 'center' }}>
                <Image
                  style={{ height: 32, width: 32 }}
                  source={require('../assets/loading.gif')}
                />
              </View>
            ) : vendedores.length > 0 ?
                vendedores.map(vendedor =>
                <View key={vendedor.id} style={{ borderBottomWidth: 1, borderBottomColor: '#ecf0f1', paddingTop: 10, paddingBottom: 10 }}>
                  <View style={{ flex: 1, flexDirection: 'row' }}>
                    <Text style={{ flex: 1, fontSize: 14, fontWeight: 'bold' }}>{vendedor.nome}</Text>
                  </View>

                  <View style={{ flexDirection: 'row' }}>
                    <View style={{ flex: 1, flexDirection: 'row' }}>
                      <Text style={{ marginTop: 7.5, fontSize: 12, fontWeight: 'bold' }}>Cartelas </Text>
                      <Text style={{ marginTop: 7.5, fontSize: 12 }}>{vendedor.quantidade_cartelas}</Text>
                    </View>
                    <View style={{ flex: 1, flexDirection: 'row' }}>
                      <Text style={{ marginTop: 7.5, fontSize: 12, fontWeight: 'bold' }}>Vendidas </Text>
                      <Text style={{ marginTop: 7.5, fontSize: 12 }}>{vendedor.quantidade_cartelas_vendidas}</Text>
                    </View>
                    <View style={{ flex: 1, flexDirection: 'row-reverse' }}>
                      <TouchableOpacity style={{}} onPress={() => navigation.navigate('cartelasVendedor', { id: route.params.sorteio.id, vendedor: vendedor.id })}>
                        <Text style={{ marginTop: 10, backgroundColor: '#1abc9c', color: '#fff', paddingLeft: 10, paddingRight: 10, borderRadius: 5, fontSize: 11, marginLeft: 5 }}>Cartelas</Text>
                      </TouchableOpacity>
                      <TouchableOpacity style={{}} onPress={() => removerVendedor(vendedor.id)}>
                        <Text style={{ marginTop: 10, backgroundColor: '#e74c3c', color: '#fff', paddingLeft: 10, paddingRight: 10, borderRadius: 5, fontSize: 11, marginLeft: 5 }}>Remover</Text>
                      </TouchableOpacity>
                    </View>
                  </View>
                </View>
              ) : (
                <View>
                  <Text style={{ textAlign: 'center' }}>Nenhum vendedor vinculado</Text>
                </View>
              )
            }
          </View>
        </View>

        <View style={{ flexDirection: 'row', marginTop: 50 }}>
          <TouchableOpacity style={{ flex: 1, marginRight: 5 }} onPress={cancelarSorteio}>
            <Text style={{ backgroundColor: '#e74c3c', color: '#fff', padding: 5, borderRadius: 5, textAlign: 'center' }}>Cancelar Sorteio</Text>
          </TouchableOpacity>

          {statusVenda == true || statusVenda == 'ABERTO' ? (
            <TouchableOpacity style={{ flex: 1, marginLeft: 5 }} onPress={fecharVendas}>
              <Text style={{ backgroundColor: '#f1c40f', color: '#fff', padding: 5, borderRadius: 5, textAlign: 'center' }}>Fechar Venda</Text>
            </TouchableOpacity>
          ) : (
            <TouchableOpacity style={{ flex: 1, marginLeft: 5 }} onPress={abrirVendas}>
              <Text style={{ backgroundColor: '#1abc9c', color: '#fff', padding: 5, borderRadius: 5, textAlign: 'center' }}>Reabrir Venda</Text>
            </TouchableOpacity>
          )}
        </View>

        <View style={{ paddingBottom: 50 }}>
          <View style={{ flexDirection: 'row', marginTop: 10 }}>
            <TouchableOpacity style={{ flex: 1 }} onPress={() => navigation.navigate('Relatorio', { sorteio: route.params.sorteio.id, vendedores })}>
              <Text style={{ color: '#1abc9c', borderWidth: 1, borderColor: '#1abc9c', padding: 5, borderRadius: 5, textAlign: 'center' }}>Ver relatório</Text>
            </TouchableOpacity>
            <TouchableOpacity style={{ flex: 1, marginLeft: 5 }} onPress={sortear}>
              <Text style={{ backgroundColor: '#1abc9c', color: '#fff', padding: 5, borderRadius: 5, textAlign: 'center' }}>Realizar Sortear</Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </View>
  );
}

export default SorteioDetalhe;