import axios from 'axios';

export const baseURL = __DEV__ ? 'http://localhost:3333' : 'https://api.masterpremio.online';

const api = axios.create({
  baseURL ,
  headers: {
    'Content-Type': 'application/json',
  }
});

api.interceptors.request.use(async (config) => {
  let user = localStorage.getItem('user');

  if (user) {
    user = JSON.parse(user);
    if (!config.headers) config.headers = {};
    config.headers['x-access-token'] = user.token;
  }

  return config;
});

export default api;
