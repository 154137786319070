import React, { useCallback, useEffect, useState } from 'react';
import { View, Text, TouchableOpacity, Image } from 'react-native';
import { Clipboard } from 'react-native';

import api from '../services/api';

const ClienteCartela = ({ route, navigation }) => {
  const [user, setUser] = useState(null);
  const [cartelas, setCartelas] = useState([]);
  const [loading, setLoading] = useState(true);

  const handleLoad = useCallback(async () => {
    setLoading(true);
    
    const { data } = await api.get(`sorteios/${route.params.sorteio_id}/clientes/${route.params.cliente.id}`);
    setCartelas(data.cartelas);

    let user = localStorage.getItem('user');
    user = JSON.parse(user);
    setUser(user);

    setLoading(false);
  }, []);

  useEffect(() => {
    handleLoad();
  }, [handleLoad]);

  const compartilharArquivo = () => {
    Clipboard.setString(`https://masterpremio.online#/cartelas/${route.params.sorteio_id}/${route.params.cliente.id}`);
  }

  const apagar = (cartela_id) => {
    const x = confirm('Deseja realmente cancelar a venda?');
    
    if (x) {
      api.delete(`sorteios/${route.params.sorteio_id}/cancelar_venda/${cartela_id}`).then(() => {
        alert('Venda cancelada com sucesso!');
        handleLoad();
      }).catch((e) => {
        if (e.response.data.msg) {
          alert(e.response.data.msg);
        } else {
          alert('Falha ao realizar venda');
        }
      })
    }
  }

  const imprimir = async (cartela_index) => {
    const htmlContent = `
    <html>

    <head>
      <style>
        @media print {
          body {
            margin: 0;
            padding: 0;
          }
        }

        .numero {
          text-align: center;
          font-size: 18px;
          border: 1px solid rgb(149, 165, 166);
          padding: 5px 30px;
          border-radius: 10px;
          margin: 5px;
        }
      </style>
    </head>

    <body>            
      <div style="flex: 1">
        <div style="background-color: #fff; padding: 10px;">
          <div style="display: flex; flex-direction: column; align-items: center">
            <img src="https://i.ibb.co/TKV3RBw/m.png" style="height: ${cartelas[0].numeros.length > 3 ? '100px' : '150px'}; width: ${cartelas[0].numeros.length > 3 ? '100px' : '150px'}">
            <p style="font-size: 9px; text-align: center; margin-top: 5px">${cartelas[0].id}</p>
          </div>

          <div style="flex: 1">
            ${cartelas[0].numeros.length > 3 ? `
              <div style="flex: 1; flex-direction: row">
                <div class="numero" style="flex: 1">
                  <p style="font-weight: bold; text-align: center; border-width: 1px; border-color: #95a5a6; padding: 2.5px; border-radius: 10px; margin: 5px">${cartelas[cartela_index].numeros[0]}</p>
                </div>
                <div class="numero" style="flex: 1">
                  <p style="font-weight: bold; text-align: center; border-width: 1px; border-color: #95a5a6; padding: 2.5px; border-radius: 10px; margin: 5px">${cartelas[cartela_index].numeros[1]}</p>
                </div>
              </div>
            
              <div style="flex: 1; flex-direction: row">
                <div class="numero" style="flex: 1">
                  <p style="font-weight: bold; text-align: center; border-width: 1px; border-color: #95a5a6; padding: 2.5px; border-radius: 10px; margin: 5px">${cartelas[cartela_index].numeros[2]}</p>
                </div>
                <div class="numero" style="flex: 1">
                  <p style="font-weight: bold; text-align: center; border-width: 1px; border-color: #95a5a6; padding: 2.5px; border-radius: 10px; margin: 5px">${cartelas[cartela_index].numeros[3]}</p>
                </div>
              </div>

              <div style="flex: 1; flex-direction: row">
                ${cartelas[0].numeros.length > 4 ? `
                  <div class="numero" style="flex: 1">
                    <p style="font-weight: bold; text-align: center; border-width: 1px; border-color: #95a5a6; padding: 2.5px; border-radius: 10px; margin: 5px">${cartelas[cartela_index].numeros[4]}</p>
                  </div>
                ` : ''}
                ${cartelas[0].numeros.length > 4 ? `
                  <div class="numero" style="flex: 1">
                    <p style="font-weight: bold; text-align: center; border-width: 1px; border-color: #95a5a6; padding: 2.5px; border-radius: 10px; margin: 5px">${cartelas[cartela_index].numeros[5]}</p>
                  </div>
                ` : ''}
              </div>
            ` : `
              <div class="numero" style="flex: 1; align-items: center">
                <p style="text-align: center; border-width: 1px; font-size: 18px; border-color: #95a5a6; padding: 5px; border-radius: 10px; margin: 5px">${cartelas[cartela_index].numeros[0]}</p>
              </div>

              <div class="numero" style="flex: 1; align-items: center; margin-top: 10px">

                <p style="text-align: center; border-width: 1px; font-size: 18px; border-color: #95a5a6; padding: 5px; border-radius: 10px; margin: 5px">${cartelas[cartela_index].numeros[1]}</p>
              </div>

              <div class="numero" style="flex: 1; align-items: center; margin-top: 10px">
                <p style="text-align: center; border-width: 1px; font-size: 18px; border-color: #95a5a6; padding: 5px; border-radius: 10px; margin: 5px">${cartelas[cartela_index].numeros[2]}</p>
              </div>
            `}
          </div>
        </div>
      </div>
    </body>
  </html>`

    const pW = window.open('', '', 'height=500, width=500')
    pW.document.write(htmlContent)
    pW.document.close()
    pW.print()
  }

  if (loading) {
    return null;
  }

  return (
    <div id='paginaprint' style={{ flex: 1, width: '100%' }}>
      <View style={{ margin: 20, flex: 1 }}>
        <View style={{ alignItems: 'center' }}>
          <Image
            style={{ height: 200, width: 200, marginTop: -50, marginBottom: -50 }}
            source={require('../assets/logo.png')}
          />
        </View>

        <View style={{ backgroundColor: '#fff', padding: 10, marginTop: 10, borderRadius: 5, flexDirection: 'row' }}>
          <View style={{ flex: 1 }}>
            <View>
              <Text style={{ fontWeight: 'bold', textAlign: 'center' }}>Nome Cliente</Text>
              <Text style={{ textAlign: 'center' }}>{route.params.cliente.nome}</Text>
            </View>
          </View>
          <View style={{ flex: 1 }}>
            <View>
              <Text style={{ fontWeight: 'bold', textAlign: 'center' }}>Whatsapp</Text>
              <Text style={{ textAlign: 'center' }}>{route.params.cliente.whatsapp}</Text>
            </View>
          </View>
        </View>
            
        <TouchableOpacity onPress={() => compartilharArquivo()}>
          <Text style={{ backgroundColor: '#1abc9c', color: '#fff', margin: 10, marginLeft: 75, marginRight: 75,padding:5, borderRadius:5, textAlign: 'center' }}>Copiar Link</Text>
        </TouchableOpacity>

        {cartelas.map((e, index) => (
          <View key={e.id} id={`cartela_${e.id}`} onPress={() => navigation.navigate('ClientesCartelas', { cliente: e })} style={{ backgroundColor: '#fff', padding: 10, borderRadius: 5, flexDirection: 'row', marginTop: 10 }}>
            <View>
              <Image
                style={{ height: cartelas[0].numeros.length > 3 ? 100 : 150, width: cartelas[0].numeros.length > 3 ? 100 : 150 }}
                source={require('../assets/logo.png')}
              />
              <Text style={{ fontSize: 9, textAlign: 'center', marginTop: 5 }}>{cartelas[0].id}</Text>
            </View>

            {cartelas[0].numeros.length > 3 ? (
              <View style={{ flex: 1 }}>
                <View style={{ flex: 1, flexDirection: 'row' }}>
                  <View style={{ flex: 1 }}>
                    <Text style={{ fontWeight: 'bold', textAlign: 'center', borderWidth: 1, borderColor: '#95a5a6', padding: 2.5, borderRadius: 10, margin: 5 }}>
                      {cartelas[index].numeros[0]}
                    </Text>
                  </View>
                  <View style={{ flex: 1 }}>
                    <Text style={{ fontWeight: 'bold', textAlign: 'center', borderWidth: 1, borderColor: '#95a5a6', padding: 2.5, borderRadius: 10, margin: 5 }}>{cartelas[index].numeros[1]}</Text>
                  </View>
                </View>

                <View style={{ flex: 1, flexDirection: 'row' }}>
                  <View style={{ flex: 1 }}>
                    <Text style={{ fontWeight: 'bold', textAlign: 'center', borderWidth: 1, borderColor: '#95a5a6', padding: 2.5, borderRadius: 10, margin: 5 }}>{cartelas[index].numeros[2]}</Text>
                  </View>
                  <View style={{ flex: 1 }}>
                    <Text style={{ fontWeight: 'bold', textAlign: 'center', borderWidth: 1, borderColor: '#95a5a6', padding: 2.5, borderRadius: 10, margin: 5 }}>{cartelas[index].numeros[3]}</Text>
                  </View>
                </View>

                <View style={{ flex: 1, flexDirection: 'row' }}>
                  {cartelas[0].numeros.length > 4 && (
                    <View style={{ flex: 1 }}>
                      <Text style={{ fontWeight: 'bold', textAlign: 'center', borderWidth: 1, borderColor: '#95a5a6', padding: 2.5, borderRadius: 10, margin: 5 }}>{cartelas[index].numeros[4]}</Text>
                    </View>
                  )}

                  {cartelas[0].numeros.length > 4 && (
                    <View style={{ flex: 1 }}>
                      <Text style={{ fontWeight: 'bold', textAlign: 'center', borderWidth: 1, borderColor: '#95a5a6', padding: 2.5, borderRadius: 10, margin: 5 }}>{cartelas[index].numeros[5]}</Text>
                    </View>
                  )}
                </View>
              </View>
            ) : (
              <View style={{ flex: 1 }}>
                <View style={{ alignItems: 'center' }}>
                  <Text style={{ textAlign: 'center', borderWidth: 1, fontSize: 18, borderColor: '#95a5a6', padding: 5, paddingLeft: 30, paddingRight: 30, borderRadius: 10, margin: 5 }}>
                    {cartelas[index].numeros[0]}
                  </Text>
                </View>

                <View style={{ alignItems: 'center', marginTop: 10 }}>
                  <Text style={{ textAlign: 'center', borderWidth: 1, fontSize: 18, borderColor: '#95a5a6', padding: 5, paddingLeft: 30, paddingRight: 30, borderRadius: 10, margin: 5 }}>
                    {cartelas[index].numeros[1]}
                  </Text>
                </View>

                <View style={{ alignItems: 'center', marginTop: 10 }}>
                  <Text style={{ textAlign: 'center', borderWidth: 1, fontSize: 18, borderColor: '#95a5a6', padding: 5, paddingLeft: 30, paddingRight: 30, borderRadius: 10, margin: 5 }}>
                    {cartelas[index].numeros[2]}
                  </Text>
                </View>
              </View>
            )}

            {(user?.tipo == 'ADMIN' || user?.tipo == 'GERENTE') && (
              <View style={{ marginTop: 10 }}>
                <TouchableOpacity style={{ flex: 1, marginRight: 5 }} onPress={() => apagar(e.id)}>
                  <Text style={{ backgroundColor: '#e74c3c', color: '#fff', padding: 5, borderRadius: 5, textAlign: 'center' }}>Apagar</Text>
                </TouchableOpacity>
              </View>
            )}

            {user?.tipo == 'VENDEDOR' && (
              <View style={{ marginTop: 10 }}>
                <TouchableOpacity style={{ flex: 1, marginRight: 5 }} onPress={() => imprimir(index)}>
                  <Text style={{ backgroundColor: '#3caee7', color: '#fff', padding: 5, borderRadius: 5, textAlign: 'center' }}>Imprimir</Text>
                </TouchableOpacity>
              </View>
            )}
          </View>
        ))}
      </View>
    </div >
  );
}

export default ClienteCartela;