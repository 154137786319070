import React, { useState } from 'react';
import { View, Text, TouchableOpacity, TextInput, Image } from 'react-native';
import api from './services/api';

const Auth = ({ navigation }) => {
  const [usuario, setUsuario] = useState('')
  const [senha, setSenha] = useState('')

  useState(() => {
    const sorteio_id = localStorage.getItem('sorteio_id');
    const cliente_id = localStorage.getItem('cliente_id');

    if (sorteio_id) {
      navigation.reset({
        index: 0,
        routes: [{
          name: 'ClientesCartelasID',
          params: {
            sorteio_id,
            cliente_id
          }
        }],
      })
    } else {
      let user = localStorage.getItem('user');

      if (user) {
        user = JSON.parse(user);
      
        if (user.tipo == 'VENDEDOR') {
          navigation.reset({
            index: 0,
            routes: [{ name: 'TabsVendedor' }]
          })
        } else {
          navigation.reset({
            index: 0,
            routes: [{ name: 'Tabs' }]
          })
        }
      }
    }
  }, [])

  const autenticacao = () => {
    if (usuario.length > 4)
      if (senha.length >= 4) {
        api.post('login', { email: usuario, senha }).then((e) => {
          if (e.status == 500) {
            alert('Usuario ou senha inválidos')
          } else {
            localStorage.setItem('user', JSON.stringify(e.data));
            
            if (e.data.tipo == 'VENDEDOR') {
              navigation.reset({
                index: 0,
                routes: [{ name: 'TabsVendedor' }]
              })
            } else {
              navigation.reset({
                index: 0,
                routes: [{ name: 'Tabs' }]
              })
            }
          }
        }).catch(function () {
          alert('Usuario e senha inválidos ou falha de comunicação com a internet.')
        })
      } else {
        alert('Senha deve conter mais de 4 digitos')
      } else {
      alert('Usuario inválido')
    }
  }

  return (
    <View style={{ flex: 1, backgroundColor: '#000', justifyContent: 'center' }}>
      <View style={{ width: '80vw', marginLeft: '10vw' }}>
        <View style={{ alignItems: 'center' }}>
          <Image
            style={{ height: 200, width: 200, marginTop: -250 }}
            source={require('./assets/logo.png')}
          />
        </View>

        <TextInput placeholder='Usuário' value={usuario} onChangeText={(e) => setUsuario(e.toLocaleLowerCase())} style={{ padding: 10, backgroundColor: '#fff', borderRadius: 5 }} />
        <TextInput secureTextEntry={true} placeholder='Senha' value={senha} onChangeText={(e) => setSenha(e)} style={{ marginTop: 10, padding: 10, backgroundColor: '#fff', borderRadius: 5 }} />

        <TouchableOpacity onPress={autenticacao}>
          <Text style={{ backgroundColor: '#1abc9c', padding: 10, borderRadius: 5, color: '#fff', textAlign: 'center', marginTop: 30 }}>Entrar</Text>
        </TouchableOpacity>
      </View>
    </View>
  );
}

export default Auth;