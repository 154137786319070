import React from 'react';

import { createNativeStackNavigator } from '@react-navigation/native-stack';

import Sorteios from '../pages/sorteios'
import SorteiosNovo from '../pages/usuario'

const Stack = createNativeStackNavigator();

const RoutesSorteio = () => (
  <Stack.Navigator>
    <Stack.Screen options={{ headerShown: false, title: 'Master Premios' }} name='Sorteio' component={Sorteios} />
    <Stack.Screen options={{ headerShown: false, title: 'Teste' }} name='SorteioNovo' component={SorteiosNovo} />
  </Stack.Navigator>
);

export default RoutesSorteio;