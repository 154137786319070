import React, { useCallback, useEffect, useState } from 'react';
import { View, Text, Image, TouchableOpacity } from 'react-native';

import api from '../services/api';

const SorteioManuntencao = ({ route, navigation }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [cartelas, setCartelas] = useState([]);

  const handleObterCartelasRepetidas = useCallback(async () => {
    const { data } = await api.get(`sorteios/${route.params.sorteio_id}/repetidas`)
    setCartelas(data)
    setIsLoading(false);
  }, [])

  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      setIsLoading(true);
      handleObterCartelasRepetidas();
    });

    return unsubscribe;
  }, []);

  const apagarCartela = async (cartelaId) => {
    const x = confirm('Deseja realmente apagar a cartela?');
    if (x) {
      await api.delete(`sorteios/${route.params.sorteio_id}/cartelas/${cartelaId}`);
      handleObterCartelasRepetidas();
    }
  }

  return (
    <View style={{ flex: 1 }}>
      <View style={{ margin: 20, flex: 1, paddingBottom: 200 }}>
        <View style={{ backgroundColor: '#fff', padding: 10, marginTop: 10, borderRadius: 5 }}>
          <View>
            {isLoading ? (
              <View style={{ backgroundColor: '#fff', padding: 10, marginTop: 10, borderRadius: 5, alignItems: 'center' }}>
                <Image
                  style={{ height: 32, width: 32 }}
                  source={require('../assets/loading.gif')}
                />
              </View>
            ) : cartelas.length > 0 ?
                cartelas.map((c, i) =>
                <View key={i} style={{ backgroundColor: '#fff', padding: 10, marginTop: 10, borderWidth: 1, borderColor: '#000', borderRadius: 5 }}>
                  {c.map((cartela, index) => (
                    <View key={cartela.id} style={{ flexDirection: 'column', paddingBottom: 10, marginBottom: 10, borderBottomWidth: c.length -1 !== index ? 1 : 0, borderColor: '#000' }}>
                      <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'center' }}>
                        {cartela.numeros.map((numero, index) => (
                          <Text key={index} style={{ flex: 1, fontWeight: 'bold', maxHeight: 25, textAlign: 'center', borderWidth: 1, borderColor: '#95a5a6', padding: 2.5, borderRadius: 10, margin: 5, fontSize: 11 }}>
                            {numero}
                          </Text>
                        ))}
                      </View>

                      {cartela.cliente && (
                        <View style={{ marginTop: 20 }}>
                          <Text style={{ backgroundColor: '#e74c3c', color: '#fff', borderRadius: 5, marginLeft: 5, textAlign: 'center' }}>Vendido</Text>
                          <Text style={{ marginTop: 5, textAlign: 'center' }}>{cartela.cliente.nome}</Text>
                          <Text style={{ marginTop: 5, textAlign: 'center' }}>{cartela.cliente.whatsapp}</Text>
                        </View>
                      )}

                      <View style={{ flexDirection: 'row', marginTop: 20 }}>
                        <TouchableOpacity style={{ flex: 1, marginRight: 5 }} onPress={() => apagarCartela(cartela.id)}>
                          <Text style={{ padding: 5, textAlign: 'center', backgroundColor: '#e74c3c', color: '#fff', borderRadius: 5 }}>Apagar</Text>
                        </TouchableOpacity>
                      </View>
                    </View>
                  ))}
                </View>
              ) : (
                <View>
                  <Text style={{ textAlign: 'center' }}>Nenhuma cartela repetida</Text>
                </View>
              )
            }
          </View>
        </View>
      </View>
    </View>
  );
}

export default SorteioManuntencao;