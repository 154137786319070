import { useCallback, useEffect, useMemo, useState } from 'react';
import { View, Text, TouchableOpacity, Modal, StyleSheet, TouchableWithoutFeedback } from 'react-native';

import api from '../services/api';

const CidadesComponent = ({ disabled, value, handleChange }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [options, setOptions] = useState([]);

  const option = useMemo(() => options.find(option => option.id == value), [options, value])

  const handleConsult = useCallback(async () => {
    const { data } = await api.get('cidades');
    setOptions(data);
  }, []);

  useEffect(() => {
    handleConsult();
  }, [handleConsult]);

  const handleSelect = (option) => {
    setModalVisible(false);
    handleChange(option ? option.id : null);
  };

  return (
    <View style={styles.container}>
      <TouchableOpacity disabled={disabled} onPress={() => setModalVisible(true)} style={{ paddingTop: 5, marginTop: 5, paddingBottom: 5, paddingLeft: 5, borderWidth: 1, borderColor: '#000', borderRadius: 5 }}>
        <Text>{option ? option.nome : 'Selecione uma opção'}</Text>
      </TouchableOpacity>

      <Modal
        animationType='none'
        transparent={true}
        visible={modalVisible}
        onRequestClose={() => setModalVisible(false)}
      >
        <TouchableWithoutFeedback onPress={() => setModalVisible(false)}>
          <View style={styles.modalContainer}>
            <View style={styles.modal}>
              {options.map((option) => (
                <TouchableOpacity
                  key={option.id}
                  style={styles.option}
                  onPress={() => handleSelect(option)}
                >
                  <Text>{option.nome}</Text>
                </TouchableOpacity>
              ))}

              <TouchableOpacity onPress={() => handleSelect(null)}>
                <Text style={styles.cancel}>Cancelar</Text>
              </TouchableOpacity>
            </View>
          </View>
        </TouchableWithoutFeedback>
      </Modal>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1
  },
  modalContainer: {
    flex: 1,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    justifyContent: 'center',
    alignItems: 'center'
  },
  modal: {
    backgroundColor: 'white',
    padding: 20,
    borderRadius: 8,
    elevation: 5
  },
  option: {
    paddingVertical: 10
  },
  cancel: {
    paddingVertical: 10,
    color: 'red'
  },
});

export default CidadesComponent;