import React from 'react';
import FontAwesome5 from '@expo/vector-icons/Ionicons';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';

const Tab = createBottomTabNavigator();

import Sorteios from './routes-sorteios'
import Usuario from '../pages/usuario'

const RoutesTabsVendedor = () =>  (
  <Tab.Navigator>
    <Tab.Screen 
      name='Sorteios' 
      component={Sorteios} 
      options={{
        tabBarIcon: ({ color, size }) => (
          <FontAwesome5 name='home-outline' color={color} size={size} />
        )
      }}
    />
    <Tab.Screen 
      name='Perfil'
      component={Usuario} 
      options={{
        tabBarIcon: ({ color, size }) => (
          <FontAwesome5 name='person-outline' color={color} size={size} />
        )
      }} 
    />
  </Tab.Navigator>
);

export default RoutesTabsVendedor;


