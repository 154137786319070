import React, { useState, useEffect, useCallback } from 'react';
import { View, Text, TouchableOpacity, TextInput, ScrollView } from 'react-native';
import api from '../../services/api';

const CartelasVendedor = ({ route, navigation }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [quantidadeCartela, setQuantidadeCartela] = useState(1);
  const [cartelas, setCartelas] = useState([]);

  const handleConsultarCartelas = useCallback(async () => {
    const { data } = await api.get(`sorteios/${route.params.id}/vendedores/${route.params.vendedor}/cartelas`);
    setCartelas(data);
    setQuantidadeCartela(data.length);
  }, []);

  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      handleConsultarCartelas();
    });

    return unsubscribe;
  }, []);

  const salvarQuantidadeCartela = () => {
    setIsLoading(true);

    try {
      if (parseInt(quantidadeCartela) > 0) {
        api.patch(`sorteios/${route.params.id}/vendedores/${route.params.vendedor}/cartelas`, {
          quantidade: quantidadeCartela,
        }).then(() =>{
          alert('Cartelas atualizadas com sucesso!');
          handleConsultarCartelas();
        }).catch((e) => {
          if (e.response.data.msg) {
            alert(e.response.data.msg);
          } else {
            alert('Falha ao atualizar quantidade de cartelas');
          }
        })
      } else {
        alert('A quantidade de cartelas deve ser maior que 0');
      }
    } catch {
      alert('Falha ao atualizar quantidade de cartela');
    } finally {
      setTimeout(() => {
        setIsLoading(false);
      }, 500);
    }
  }

  const atualizaQuantidade = (e) => {
    try {
      let x = 0;
      if (e.length > 0) {
        x = parseInt(e)
      }

      setQuantidadeCartela(x)
    } catch {
      setQuantidadeCartela(1)
    }
  }

  return (
    <View style={{ flex: 1 }}>
      <View style={{ margin: 20, flex: 1 }}>
        <View style={{ backgroundColor: '#fff', padding: 10, marginTop: 10, borderRadius: 5 }}>
          <Text style={{ fontSize: 12 }}>Quantidade Cartela</Text>
          <TextInput keyboardType='number-pad' onChangeText={atualizaQuantidade} value={quantidadeCartela == 0 ? '0' : quantidadeCartela} style={{ paddingTop: 5, marginTop: 5, paddingBottom: 5, paddingLeft: 5, borderWidth: 1, borderColor: '#000', borderRadius: 5 }} />

          <View style={{ flexDirection: 'row', marginTop: 20 }}>
            <TouchableOpacity disabled={isLoading} onPress={salvarQuantidadeCartela} style={{ flex: 1, marginRight: 5 }}>
              <Text style={{ padding: 5, textAlign: 'center', backgroundColor: isLoading ? '#C4C4C4' : '#1abc9c', color: '#fff', borderRadius: 5 }}>Salvar</Text>
            </TouchableOpacity>
          </View>
        </View>

        <ScrollView style={{ paddingTop: 10, paddingBottom: 10, marginTop: 10, marginBottom: 10 }}>
          {cartelas.length > 0 ? (
            cartelas.map(e => (
              <View style={{ backgroundColor: '#fff', padding: 10, marginTop: 10, borderRadius: 5 }}>
                <View style={{ flexDirection: 'row' }}>
                  <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'center' }}>
                    {e.numeros.map((numero, index) => (
                      <Text key={index} style={{ flex: 1, fontWeight: 'bold', maxHeight: 25, textAlign: 'center', borderWidth: 1, borderColor: '#95a5a6', padding: 2.5, borderRadius: 10, margin: 5, fontSize: 11 }}>
                        {numero}
                      </Text>
                    ))}
                  </View>

                  <View style={{ flex: 1, paddingTop: 6.5 }}>
                    <Text style={{ backgroundColor: !e.cliente_id ? '#1abc9c' : '#e74c3c', color: '#fff', borderRadius: 5, marginLeft: 5, textAlign: 'center' }}>{!e.cliente_id ? 'Disponivel' : 'Vendido'}</Text>
                    {e.cliente != null && e.cliente.nome != '' ? <Text style={{ marginTop: 5, textAlign: 'center' }}>{e.cliente.nome}</Text> : null}
                    {e.cliente != null && e.cliente.whatsapp != '' ? <Text style={{ textAlign: 'center' }}>{e.cliente.whatsapp}</Text> : null}
                  </View>
                </View>
              </View>
            ))) : (
              <View style={{ backgroundColor: '#fff', padding: 10, marginTop: 10, borderRadius: 5 }}>
                <Text style={{ fontSize: 12, textAlign: 'center', color: '#95a5a6' }}>Nenhuma cartela cadastrada</Text>
              </View>
            )
          }
        </ScrollView>
      </View>
    </View >
  );
}

export default CartelasVendedor;