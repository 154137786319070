import React, { useEffect, useState } from 'react';
import { View, Text, ScrollView, TouchableOpacity } from 'react-native';

const Cadastros = ({ navigation }) => {
  const [usuario, setUsuario] = useState(null);

  useEffect(() => {
    let user = localStorage.getItem('user');

    if (user) {
      user = JSON.parse(user);
      setUsuario(user);
    }
  }, []);

  if (!usuario) {
    return null;
  }
 
  return (
    <View style={{ flex: 1 }}>
      <View style={{ margin: 20, flex: 1 }}>
        <ScrollView style={{ paddingBottom: 50 }}>
          {usuario.tipo == 'ADMIN' && (
            <TouchableOpacity 
              style={{ flexDirection: 'row', backgroundColor: '#fff', padding: 10, marginTop: 10, borderRadius: 5 }}
              onPress={() => navigation.navigate('Cidades')}
            >
              <View style={{ flex: 2 }}>
                <Text style={{ flex: 1, fontWeight: 'bold' }}>Cidades</Text>
              </View>
            </TouchableOpacity>
          )}

          {usuario.tipo == 'ADMIN' && (
            <TouchableOpacity 
              style={{ flexDirection: 'row', backgroundColor: '#fff', padding: 10, marginTop: 10, borderRadius: 5 }}
              onPress={() => navigation.navigate('Gerentes')}
            >
              <View style={{ flex: 2 }}>
                <Text style={{ flex: 1, fontWeight: 'bold' }}>Gerentes</Text>
              </View>
            </TouchableOpacity>
          )}

          <TouchableOpacity 
            style={{ flexDirection: 'row', backgroundColor: '#fff', padding: 10, marginTop: 10, borderRadius: 5 }}
            onPress={() => navigation.navigate('Vendedores')}
          >
            <View style={{ flex: 2 }}>
              <Text style={{ flex: 1, fontWeight: 'bold' }}>Vendedores</Text>
            </View>
          </TouchableOpacity>
        </ScrollView>
      </View>
    </View>
  );
}

export default Cadastros;