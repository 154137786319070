import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { View, Text, TouchableOpacity, TextInput, ScrollView } from 'react-native';
import api from '../../services/api';

import { useIsFocused } from '@react-navigation/native';

const VenderCartela = ({ route, navigation }) => {
  const isFocused = useIsFocused();

  const [isLoading, setIsLoading] = useState(false);
  const [cartelas, setCartelas] = useState([]);

  const [nome, setNome] = useState('');
  const [whatsapp, setWhatsapp] = useState('');

  const idVendedor = useMemo(() => {
    return route.params.vendedor_id ?? route.params.vendedor;
  }, []);

  const selecionaSorteio = useCallback(async () => {
    const { data } = await api.get(`sorteios/${route.params.sorteio.id}/vendedores/${idVendedor}/cartelas`)
    setCartelas(data);
  }, []);

  useEffect(() => {
    let user = localStorage.getItem('user');
    user = JSON.parse(user);

    selecionaSorteio();
  }, [isFocused, navigation]);

  const selecionarCartela = (id) => {
    const xcartelas = [...cartelas];
    for (let i = 0; i < xcartelas.length; i++) {
      if (xcartelas[i].id == id) {
        xcartelas[i].cliente_id = true;
      }
    }
    setCartelas(xcartelas);
  }

  const removeCartela = (id) => {
    const xcartelas = [...cartelas];
    for (let i = 0; i < xcartelas.length; i++) {
      if (xcartelas[i].id == id) {
        delete xcartelas[i].cliente_id;
      }
    }
    setCartelas(xcartelas);
  }

  const vender = () => {
    const xCartelas = []

    for (let i = 0; i < cartelas.length; i++) {
      if (typeof cartelas[i].cliente_id === 'boolean') {
        xCartelas.push({ id: cartelas[i].id })
      }
    }

    if (!nome.length > 0) {
      return alert('Nome deve conter pelo menos 1 digito');
    }

    if (!whatsapp.length > 0) {
      return alert('Informe o whatsapp do cliente');;
    }

    setIsLoading(true);

    const dados = {
      cartelas: xCartelas,
      nome,
      whatsapp
    }

    try {
      api.post(`sorteios/${route.params.sorteio.id}/vender`, dados).then(() => {
        alert('Venda realizada com sucesso!');
        navigation.goBack();
      }).catch((e) => {
        if (e.response.data.msg) {
          alert(e.response.data.msg);
        } else {
          alert('Falha ao realizar venda');
        }
      })
    } catch {
      alert('Falha ao realizar venda');
    } finally {
      setTimeout(() => {
        setIsLoading(false);
      }, 500);
    }
  }

  return (
    <View style={{ flex: 1 }}>
      <View style={{ margin: 20, flex: 1 }}>
        <View style={{ backgroundColor: '#fff', padding: 10, marginTop: 10, borderRadius: 5 }}>
          <Text style={{ fontSize: 12 }}>Nome</Text>
          <TextInput onChangeText={setNome} value={nome} style={{ paddingTop: 5, marginTop: 5, paddingBottom: 5, paddingLeft: 5, borderWidth: 1, borderColor: '#000', borderRadius: 5 }} />

          <Text style={{ fontSize: 12, marginTop: 5 }}>Whatsapp</Text>
          <TextInput onChangeText={setWhatsapp} value={whatsapp} style={{ paddingTop: 5, marginTop: 5, paddingBottom: 5, paddingLeft: 5, borderWidth: 1, borderColor: '#000', borderRadius: 5 }} />
          
          {route.params.statusVenda == 'ABERTO' ? (
            <TouchableOpacity disabled={isLoading} onPress={vender} style={{ flex: 1, marginTop: 20 }}>
              <Text style={{ padding: 5, textAlign: 'center', backgroundColor: isLoading ? '#C4C4C4' : '#1abc9c', color: '#fff', borderRadius: 5 }}>Vender</Text>
            </TouchableOpacity>
          ) : (
            <View style={{ flex: 1, marginTop: 20 }}>
              <Text style={{ padding: 5, textAlign: 'center', backgroundColor: '#e74c3c', color: '#fff', borderRadius: 5 }}>Vendas Fechadas</Text>
            </View>
          )}
        </View>

        <ScrollView style={{ paddingTop: 10, paddingBottom: 10, marginTop: 10, marginBottom: 10 }}>
          {cartelas.map(cartela =>
            <View key={cartela.id} style={{ backgroundColor: '#fff', padding: 10, marginTop: 10, borderRadius: 5 }}>
              <View style={{ flexDirection: 'row' }}>
                <View style={{ flex: 1 }}>
                  <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'center' }}>
                    {cartela.numeros.map((numero, index) => (
                      <Text key={index} style={{ flex: 1, fontWeight: 'bold', maxHeight: 25, textAlign: 'center', borderWidth: 1, borderColor: '#95a5a6', padding: 2.5, borderRadius: 10, margin: 5, fontSize: 11 }}>
                        {numero}
                      </Text>
                    ))}
                  </View>
                </View>

                <View style={{ flex: 1 }}>
                  {typeof cartela.cliente_id === 'number' && (
                    <View>
                      <Text style={{ backgroundColor: '#e74c3c', color: '#fff', borderRadius: 5, marginLeft: 5, textAlign: 'center' }}>Vendido</Text>
                      <Text style={{ marginTop: 5, textAlign: 'center' }}>{cartela.cliente.nome}</Text>
                    </View>
                  )}

                  {!cartela.cliente_id && (
                    <TouchableOpacity disabled={route.params.statusVenda != 'ABERTO'} onPress={() => selecionarCartela(cartela.id)}>
                      <Text style={{ backgroundColor: '#95a5a6', color: '#000', borderRadius: 5, marginLeft: 5, textAlign: 'center' }}>Selecionar</Text>
                    </TouchableOpacity>
                  )}

                  {typeof cartela.cliente_id === 'boolean' && (
                    <TouchableOpacity onPress={() => removeCartela(cartela.id)}>
                      <Text style={{ backgroundColor: '#1abc9c', color: '#fff', borderRadius: 5, marginLeft: 5, textAlign: 'center' }}>Selecionado</Text>
                    </TouchableOpacity>
                  )}
                </View>
              </View>
            </View>
          )}

          {!Boolean(cartelas.length) && (
            <View style={{ backgroundColor: '#fff', padding: 10, marginTop: 10, borderRadius: 5 }}>
              <Text style={{ fontSize: 12, textAlign: 'center', color: '#95a5a6' }}>Nenhuma cartela cadastrada</Text>
            </View>
          )}
        </ScrollView>
      </View>
    </View >
  );
}

export default VenderCartela;