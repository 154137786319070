import React, { useEffect, useState } from 'react';
import { View, Text, TouchableOpacity, TextInput } from 'react-native';
import api from '../services/api';

const Usuario = ({ navigation }) => {
  const [usuario, setUsuario] = useState('')
  const [senha, setSenha] = useState('');

  useEffect(() => {
    let user = localStorage.getItem('user');

    if (user) {
      user = JSON.parse(user);
      setUsuario(user.nome);
    }
  }, []);

  const deslogar = () => {
    const x = confirm('Deseja realmente deslogar da sua conta?');
    if (x) {
      localStorage.removeItem('user')
      navigation.reset({
        index: 0,
        routes: [{ name: 'Auth' }]
      })
    }
  }

  const trocarSenha = () => {
    if (senha.length >= 6) {
      api.patch('trocar_senha', { senha }).then(() => {
        alert('Senha alterada com sucesso!')
      }).catch(() => {
        alert('')
      })
    } else {
      alert('')
    }
  }

  return (
    <View style={{ flex: 1 }}>
      <View style={{ margin: 20, flex: 1 }}>
        <View style={{ backgroundColor: '#fff', padding: 10, marginTop: 10, borderRadius: 5 }}>
          <Text style={{ textAlign: 'center', fontWeight: 'bold' }}>{usuario}</Text>

          <Text style={{ textAlign: 'center', marginTop: 20 }}>Nova senha</Text>
          <TextInput onChangeText={(e) => setSenha(e)} value={senha} style={{ textAlign: 'center', paddingTop: 5, marginTop: 5, paddingBottom: 5, paddingLeft: 5, borderWidth: 1, borderColor: '#000', borderRadius: 5 }} />

          <TouchableOpacity onPress={() => trocarSenha()}>
            <Text style={{ backgroundColor: '#1abc9c', marginTop: 10, textAlign: 'center', color: '#fff', padding: 7.5, borderRadius: 5 }}>Trocar senha</Text>
          </TouchableOpacity>
        </View>


        <TouchableOpacity onPress={() => deslogar()}>
          <Text style={{ backgroundColor: '#e74c3c', marginTop: 30, textAlign: 'center', color: '#fff', padding: 7.5, borderRadius: 5 }}>Deslogar</Text>
        </TouchableOpacity>
      </View>
    </View>
  );
}

export default Usuario;