import React, { useCallback, useEffect, useState } from 'react';
import { View, Text, TouchableOpacity,Image, ScrollView, TextInput } from 'react-native';
import api from '../services/api';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';

const Clientes = ({ route, navigation }) => {
  const [loading, setLoading] = useState(true);
  const [clientes, setClientes] = useState([]);
  const [pesquisa, setPesquisa] = useState('');

  const handleObterClientes = useCallback(async () => {
    setLoading(true);

    const sorteio = route.params.sorteio;
    const { data } = await api.get(`sorteios/${sorteio.id}/clientes`);

    setClientes(data);
    setLoading(false);
  }, []);

  useEffect(() => {
    handleObterClientes();
  }, [handleObterClientes]);

  return (
    <View style={{ flex: 1 }}>
      <View style={{ margin: 20, flex: 1 }}>
        <ScrollView style={{ marginBottom: 15, paddingTop: 15, paddingBottom: 15 }}>
          {loading && (
            <View style={{ backgroundColor: '#fff', padding: 10, marginTop: 10, borderRadius: 5, alignItems: 'center' }}>
              <Image
                style={{ height: 32, width: 32 }}
                source={require('../assets/loading.gif')}
              />
            </View>
          )}

          {Boolean(!loading && clientes.length) && (
            <View style={{ flex: 1, marginBottom: 5 }}>
              <Text style={{ fontSize: 12 }}>Pesquisar</Text>
              <TextInput
                style={{
                  paddingTop: 5,
                  marginTop: 5,
                  paddingBottom: 5,
                  paddingLeft: 5,
                  borderWidth: 1,
                  borderColor: '#000',
                  borderRadius: 5
                }}
                value={pesquisa}
                onChangeText={setPesquisa}
              />
            </View>
          )}

          {!loading && clientes
            .filter(c => c.nome.toLowerCase().includes(pesquisa.toLowerCase()) || c.whatsapp.toLowerCase().includes(pesquisa.toLowerCase()))
            .map(cliente => (
                <View key={cliente.id} style={{ backgroundColor: '#fff', padding: 10, marginTop: 10, borderRadius: 5 }}>
                  <TouchableOpacity onPress={() => navigation.navigate('ClientesCartelas', { sorteio_id: route.params.sorteio.id, cliente })}  style={{ flexDirection: 'row' }}>
                    <View style={{ flex: 1 }}>
                      <View>
                        <Text style={{ fontWeight: 'bold' }}>Cliente</Text>
                      </View>
                      <View>
                        <Text>{cliente.nome}</Text>
                      </View>
                    </View>
                    <View style={{ flex: 1 }}>
                      <View>
                        <Text style={{ fontWeight: 'bold' }}>Whatsapp</Text>
                      </View>
                      <View>
                        <Text>{cliente.whatsapp}</Text>
                      </View>
                    </View>
                    <View style={{ flex: 1 }}>
                      <View>
                        <Text style={{ fontWeight: 'bold' }}>Cartelas</Text>
                      </View>
                      <View>
                        <Text>{cliente._count.cartelas}</Text>
                      </View>
                    </View>
                  </TouchableOpacity>
            </View>
          ))}

          {(!loading && !clientes.length) && (
            <View style={{ backgroundColor: '#fff', padding: 10, marginTop: 10, borderRadius: 5 }}>
              <Text style={{ textAlign: 'center' }}>Nenhum venda realizada</Text>
            </View>
          )}
        </ScrollView>
      </View>
    </View>
  );
}

export default Clientes;