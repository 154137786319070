import React, { useState } from 'react';
import { View, Text, TouchableOpacity, TextInput } from 'react-native';
import api from '../../services/api';

const CriarCidade = ({ navigation }) => {
  const [nome, setNome] = useState('');

  const handleSubmit = () => {
    if (!nome.length) {
      return alert('Nome deve conter pelo menos 1 digito');
    }

    api.post('cidades', { nome }).then(() => {
      navigation.goBack();
    }).catch((e) => {
      if (e.response.data.msg) {
        alert(e.response.data.msg)
      } else {
        alert('Falha ao cadastrar a cidade, por favor verifique todos os campos')
      } 
    })
  }

  return (
    <View style={{ flex: 1 }}>
      <View style={{ margin: 20, flex: 1 }}>
        <View style={{ backgroundColor: '#fff', padding: 10, marginTop: 10, borderRadius: 5 }}>
          <Text style={{ fontSize: 12 }}>Nome da cidade</Text>
          <TextInput onChangeText={setNome} value={nome} style={{ paddingTop: 5, marginTop: 5, paddingBottom: 5, paddingLeft: 5, borderWidth: 1, borderColor: '#000', borderRadius: 5 }} />
        </View>

        <View style={{ display: 'flex', flexDirection: 'row', marginTop: 25 }}>
          <TouchableOpacity onPress={handleSubmit} style={{ flex: 1, marginLeft: 5 }}>
            <Text style={{ padding: 5, textAlign: 'center', backgroundColor: '#1abc9c', color: '#fff', borderRadius: 5 }}>Salvar</Text>
          </TouchableOpacity>
        </View>
      </View>
    </View >
  );
}

export default CriarCidade;